import {useProductDataStore} from '@/store/product-data'

export default {
  methods: {
    getBrandByCode(code, locale) {
      const storebrands = useProductDataStore().brands
      let brand = storebrands?.find(b => b.Code === code && b.locale === locale)
      if (brand === undefined) {
        brand = storebrands?.find(b => b.Code === code && b.locale === this.$i18n.defaultLocale)
      }
      return brand
    },
    getBrandByName(brand, locale) {
      const storebrands = useProductDataStore().brands
      let storedBrand = storebrands?.find(b => b.Brand === brand && b.locale === locale)
      if (storedBrand === undefined) {
        storedBrand = storebrands?.find(b => b.Brand === brand && b.locale === this.$i18n.defaultLocale)
      }
      return storedBrand
    }
  }
}
